import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

document.addEventListener("turbo:load", function() {
    if ($('#ckeditor').length > 0) {
        ClassicEditor
            .create(document.querySelector('#ckeditor'), {
                // ...
                link: {
                    decorators: {
                        toggleDownloadable: {
                            mode: 'manual',
                            label: 'Downloadable',
                            attributes: {
                                download: 'file'
                            }
                        },
                        openInNewTab: {
                            mode: 'manual',
                            label: 'Open in a new tab',
                            defaultValue: true,			// This option will be selected by default.
                            attributes: {
                                target: '_blank',
                                rel: 'noopener noreferrer'
                            }
                        }
                    }
                }
            } )
            .then(editor => {
                window.editor = editor;
            })
            .catch(error => {
                console.error('There was a problem initializing the editor.', error);
            });
    }

 })