import jquery from 'jquery'
window.jQuery = jquery
window.jquery = jquery
window.$ = jquery
import Rails from '@rails/ujs'
import '@hotwired/turbo-rails'
require( 'datatables.net' )( window, $ )
import micromodal from './src/micromodal'
window.MicroModal = micromodal
import './src/pagination'
import './src/infinite_scrolling'
import './src/weekly_nets'
import './src/ckeditor'

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

