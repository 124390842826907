document.addEventListener("turbo:load", function() {
if ($('#infinite-scrolling').length > 0) {
    $('#box-wrapper').on('scroll', function() {
        var next_page = $('.next_page').attr('href')
        if ($('#box-wrapper').scrollTop() > $('#box-wrapper').height() - $('#box-wrapper').height() - 60) {
            //$('.pagination').html('<img src="/loading.gif" alt="Loading..." title="Loading..." />');
            $('.pagination').remove();
            if (typeof next_page != 'undefined') {
                $.getScript(next_page)
            }
        }

    });
}})