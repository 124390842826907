/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//import * as sigma from "sigma"
//window.sigma = sigma

import { Graph } from "graphology"
import { Sigma } from "sigma"
import louvain from 'graphology-communities-louvain';
const Switchery = require('@flexem/switchery/dist/switchery')
window.Switchery = Switchery
import {saveAsPNG} from './saveGraph'
import svgRenderer from 'graphology-svg/renderer'

import FA2Layout from "graphology-layout-forceatlas2/worker";
import forceAtlas2 from "graphology-layout-forceatlas2";


let table;

document.addEventListener("turbo:before-cache", function() {
    if (typeof table !== 'undefined' && table !== null) {
        table.destroy();
        table = null;
    }
})


document.addEventListener("turbo:load", function() {

    // Klappmenü-zeug
    $('#menuBtnUp').click(function() {
        $('#menuBtnUp').hide()
        $('#menuBtnDown').show()
        $('#GraphMenu').hide()
    })
    $('#menuBtnDown').click(function() {
        $('#menuBtnUp').show()
        $('#menuBtnDown').hide()
        $('#GraphMenu').show()
    })

    if (/Mobi/.test(navigator.userAgent)) {
        $('#menuBtnUp').hide()
        $('#menuBtnDown').show()
        $('#GraphMenu').hide()
    }

    $.extend(true, $.fn.dataTable.defaults, {
        pagingType: 'simple',
        info: true,
        processing: true,
        autoWidth: false,
        deferRender: true,
        language: {
            emptyTable: 'Keine Daten vorhanden',
            lengthMenu: 'Zeige _MENU_ Einträge',
            info: 'Zeige Seite _PAGE_ von _PAGES_',
            infoEmpty: '0 bis 0 von 0 Einträgen',
            infoFiltered: '(gefiltert aus _MAX_ Einträgen)',
            search: 'Suchen',
            infoPostFix: '',
            infoThousands: '.',
            processing: 'Bitte warten...',
            loadingRecords: 'Wird geladen...',
            zeroRecords: 'Keine Einträge vorhanden',
            aria: {
                sortAscending: ': aktivieren, um Spalte aufsteigend zu sortieren',
                sortDescending: ': aktivieren, um Spalte absteigend zu sortieren'
            },
            paginate: {
                previous: 'Vorige',
                next: 'Nächste'
            }
        }
    });

    table = $('#nets_table').DataTable( {
        "order": [[ 0, "DESC" ]]
    });


    $('#netz_der_woche').click(function(){
        _paq.push(['trackEvent', 'CTA - Button', 'CTA-oben']);
    });
    $('#netz_der_woche2').click(function(){
        _paq.push(['trackEvent', 'CTA - Button', 'CTA-unten']);
    });



    if ($('#graph-container').length) {
        if (graphJSON.length < 1 || graphJSON.edges.length < 1) {
            $('#graph-container').text('Es gibt keine Daten für diesen Graph.');
            return;
        }

        const container = document.getElementById("graph-container")

        const graph = new Graph();

        graph.import(graphJSON)

        // node sizes
        const sizes = graph.nodes().map(function (node) { return graph.getNodeAttribute(node, 'size') })
        const minNodeSize = Math.min.apply(Math, sizes)
        const maxNodeSize = Math.max.apply(Math, sizes)
        const minSize = 2, maxSize = 14;
        graph.updateEachNodeAttributes((node, attr) => {
            //attr.size
            return {
                ...attr,
                size: minSize + ((attr.size - minNodeSize) / (maxNodeSize - minNodeSize)) * (maxSize - minSize)
            }
        })

        const layout = new FA2Layout(graph, {settings: {gravity: 1, barnesHutOptimize: true, adjustSizes: true, scalingRatio: 100}, weighted: true});

        var zoomInBtn = document.getElementById("zoom-in");
        var zoomOutBtn = document.getElementById("zoom-out");
        var zoomResetBtn = document.getElementById("zoom-reset");
        var renderer = new Sigma(graph, container, {
            minCameraRatio: 0.1,
            maxCameraRatio: 10,
            allowInvalidContainer: true
        });
        var camera = renderer.getCamera();
        // Bind zoom manipulation buttons
        zoomInBtn.addEventListener("click", function () {
            camera.animatedZoom({ duration: 600 });
        });
        zoomOutBtn.addEventListener("click", function () {
            camera.animatedUnzoom({ duration: 600 });
        });
        zoomResetBtn.addEventListener("click", function () {
            camera.animatedReset({ duration: 600 });
        });

        camera.setState({ratio: cameraRatio});

        "use strict";
        /**
         * This example showcases sigma's reducers, which aim to facilitate dynamically
         * changing the appearance of nodes and edges, without actually changing the
         * main graphology data.
         */
        var __assign = (this && this.__assign) || function () {
            __assign = Object.assign || function(t) {
                for (var s, i = 1, n = arguments.length; i < n; i++) {
                    s = arguments[i];
                    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                        t[p] = s[p];
                }
                return t;
            };
            return __assign.apply(this, arguments);
        };
        //Object.defineProperty(exports, "__esModule", { value: true });

        var state = { searchQuery: "" };
        function setHoveredNode(node) {
            if (node) {
                state.hoveredNode = node;
                state.hoveredNeighbors = new Set(graph.neighbors(node));
            }
            else {
                state.hoveredNode = undefined;
                state.hoveredNeighbors = undefined;
            }
            // Refresh rendering:
            renderer.refresh();
        }
        // Bind graph interactions:
        renderer.on("enterNode", function (_a) {
            var node = _a.node;
            setHoveredNode(node);
        });
        renderer.on("leaveNode", function () {
            setHoveredNode(undefined);
        });
        // Render nodes accordingly to the internal state:
        // 1. If a node is selected, it is highlighted
        // 2. If there is query, all non-matching nodes are greyed
        // 3. If there is a hovered node, all non-neighbor nodes are greyed
        renderer.setSetting("nodeReducer", function (node, data) {
            const res = __assign({}, data)
            if (state.hoveredNeighbors && !state.hoveredNeighbors.has(node) && state.hoveredNode !== node) {
                res.hidden = true
            }
            if (state.selectedNode === node) {
                res.highlighted = true
            }
            else if (state.suggestions && !state.suggestions.has(node)) {
                res.hidden = true
            }
            return res;
        });

        renderer.setSetting("labelDensity", .5);
        renderer.setSetting("labelGridCellSize", 10);
        renderer.setSetting("labelRenderedSizeThreshold", labelRenderedSizeThreshold);
        // Render edges accordingly to the internal state:
        // 1. If a node is hovered, the edge is hidden if it is not connected to the
        //    node
        // 2. If there is a query, the edge is only visible if it connects two
        //    suggestions
        renderer.setSetting("edgeReducer", function (edge, data) {
            var res = __assign({}, data);
            if (state.hoveredNode && !graph.hasExtremity(edge, state.hoveredNode)) {
                res.hidden = true;
            }
            if (state.suggestions && (!state.suggestions.has(graph.source(edge)) || !state.suggestions.has(graph.target(edge)))) {
                res.hidden = true;
            }
            return res;
        });

        $('#animationSwitch').change(function () {
            if($(this).is(":checked")){
                layout.start()
            }
            else {
                layout.stop()
            }
        });

        // Switchery
        if ($(".js-switch")[0]) {
            var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
            elems.forEach(function (html) {
                if(!html.getAttribute('data-switchery')) {
                    var switchery = new Switchery(html, {
                        color: '#557aa4'
                    });
                }
            });
        }

        $('#backgroundSwitch').change(function () {
            if($(this).is(":checked")){
                $('footer').css('background-color','#1f1f1f');
                $('#GraphMenu').css('background-color','#272727');
                $('#graph-container').css('background','rgb(30, 30, 30)');
                $('.first-block').css('background-color','rgb(30, 30, 30)');
                $('.second-block').css('background-color','rgb(30, 30, 30)');
                $('.box-with-border').css('color','#fff');
                $('.cta-text').css('color','#fff');
                $('.headline').css('color','#fff');
                $('.headline-3').css('color','#fff');
                $('.text-big').css('color','#fff');
                $('.white-text').css('color','#fff');
                $('.modal__container').css('background-color','#242424')
                $('.modal__content').css('color','#fff','important')
                $('.modal__footer').css('background-color','#242424')
                $("body").css({"background-color":'rgb(30, 30, 30)'});
                renderer.setSetting("labelColor", {color: "#fff"});
            }
            else {
                $('#GraphMenu').css('background-color','rgb(235, 246, 255)');
                $('#graph-container').css('background-color','rgba(255, 255, 255, 0)');
                $('.first-block').css('background-color','rgba(255, 255, 255, 0.7)');
                $('.second-block').css('background-color','rgba(255, 255, 255, 0.7)');
                $('.box-with-border').css('color','#000');
                $('.cta-text').css('color','#000');
                $('.headline').css('color','#000');
                $('.headline-3').css('color','#000');
                $('.text-big').css('color','#000');
                $('.white-text').css('color','#000');
                $('.modal__container').css('background-color','#fff')
                $('.modal__content').css('color','#000','important')
                $('.modal__footer').css('background-color','#fff')
                $("body").css({"background-color":'#E6E6E6'});
                renderer.setSetting("labelColor", {color: "#000"});
            }
        });
        var defaultEdgeColor = graphJSON.edges[0].attributes.color

        // edge and node colour change
        const communities = louvain(graph)
        const nodeColours = [
            nodeColor1,
            nodeColor2,
            nodeColor3,
            nodeColor4,
            nodeColor5
        ]
        const edgeColours = [
            edgeColor1,
            edgeColor2,
            edgeColor3,
            edgeColor4,
            edgeColor5
        ]

        graph.updateEachEdgeAttributes((edge, attr) => {
            return {
                ...attr,
                color: edgeColours[communities[graph.source(edge)]] || defaultEdgeColor
            }
        })
        graph.updateEachNodeAttributes((node, attr) => {
            return {
                ...attr,
                color: nodeColours[communities[node]] || defaultNodeColor
            }
        })


        var saveBtn = document.getElementById("btn-png");
        saveBtn.addEventListener("click", function () {
            var layers = ["edges", "nodes", "labels"]
            saveAsPNG(renderer, layers, Sigma)
        });


    }
    $('form[id^=netz_der_woche]').submit(function(e) {
        if($(this).closest('form')[0].checkValidity()){
            e.preventDefault();
            var data = $(this).serialize();
            $.ajax({
                url: '/contacts',
                type: 'post',
                data: data
            }).done(function() {
                MicroModal.show('success');
                $('.email-field').prop('disabled', true);
                $('.submit-button').prop('disabled', true);

            }).fail(function(jqXHR, textStatus, errorThrown) {
                if (jqXHR.responseJSON.errors[0] == 'Bitte mindestens ein Feld ausfüllen') {
                    var errorHtml = '';
                    $(jqXHR.responseJSON.errors).each(function(i, error) {
                        errorHtml += '<li>' + error + '</li>';
                    });
                    $('#fail-content').html('<ul>' + errorHtml + '</ul>');
                }
                MicroModal.show('fail');
            });
        }
    });

})
